import axios from "axios";
import { awsUrl2 } from "../config";

export async function sendEmailNotification(templateName, details, subject) {
  const templateFunction = templates.find(
    (template) => templateName in template
  )[templateName];

  // Generate the email body using the template
  const html = templateFunction(details);
  const body = {
    to: mailing_list,
    html,
    subject,
  };

  const response = await axios.post(
    `${awsUrl2}/api/sendEmailNotification`,
    // `http://localhost:3000/dev/api/sendEmailNotification`,
    body
  );
  return response;
}

const mailing_list = [
  "helpdesk@insurecomp.com",
  "info.trigger@insurecomp.com",
  // "ajaykumar@insurecomp.com",
];

const templates = [
  {
    ERROR: (details) => {
      const template = `<p>
        ${details.error} - </br>
        Client - ${details.client_name} </br>
        Company Name - ${details.company_name}
        </br>
        Id - ${details.user_email_id}</br>
        Environment - ${process.env.REACT_APP_MODE}
    </p>`;
      return template;
    },
  },
];
